import React, { useRef, useState } from 'react';
import { App, Form, Table, type TablePaginationConfig, Tag } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
} from '@root/components';
import { FinTaskForm, type FormHandlers } from '@root/feature/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { toDataSource } from '@root/lib';
import {
  useDeleteFinTaskMutation,
  useGetFinTasksQuery,
} from '@root/store/api/fin-task';
import type { Account, Card } from '@root/store/types';
import type { FinTask, FinTaskStatus } from '@root/store/types/fin-task';
import type { FinTaskType } from '@root/store/types/resources';

const FinTasksLayout: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const {
    data,
    isLoading,
    isError: isDataError,
  } = useGetFinTasksQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });

  // eslint-disable-next-line max-len
  const [deleteFinTask, { isError: isDeleteError }] =
    useDeleteFinTaskMutation();

  const isError = isDeleteError || isDataError;

  useErrorNotification(isError, t('Что-то пошло не так'));

  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      message.error({
        content: t('Не удалось создать запись'),
      });
    }
  };

  const handleDeleteRow = (row: FinTask) => () => {
    deleteFinTask(row.id as number);
  };

  const columns: ColumnType<FinTask>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: t('Тип'),
      dataIndex: 'type',
      key: 'type',
      render: (type: FinTaskType) => type.name,
    },
    {
      title: t('Аккаунт'),
      dataIndex: 'account',
      key: 'account',
      render: (account: Account) => `[${account.id}] ${account.login}`,
    },
    {
      title: t('Данные'),
      dataIndex: 'task_data',
      key: 'task_data',
      render: data => <ReactJson src={data} collapsed />,
    },
    {
      title: t('Статус'),
      dataIndex: 'status',
      key: 'status',
      render: (status: FinTaskStatus) => <Tag>{status}</Tag>,
    },
    {
      title: t('Создано'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      render: (row: FinTask) => (
        <TableActions
          onDelete={handleDeleteRow(row)}
          disabled={row.status !== 'new'}
        />
      ),
    },
  ];

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<FinTask>(data?.data || []);

  return (
    <Crud
      ref={crudRef}
      title={t('Финансовые задачи')}
      drawerTitle={t('Добавить задачу')}
      drawerContent={<FinTaskForm ref={formRef} onSubmit={handleSubmit} />}
      onDrawerClose={formRef.current?.resetFields}
    >
      <Form form={form} component={false}>
        <Table<FinTask>
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
          dataSource={rows}
          loading={isLoading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Form>
    </Crud>
  );
};

export default FinTasksLayout;
