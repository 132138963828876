import React from 'react';
import { Table, Tag } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { toDataSource } from '@root/lib';
import type {
  AccountResponseItem,
  Bank,
  Card,
  CustomerDetails,
} from '@root/store/types';

type Props = {
  data: CustomerDetails['devices'];
};

const CustomerAccounts: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const accounts = data.flatMap(device => device.accounts || []).flat();
  const expandedRowRender = (record: AccountResponseItem) => {
    const columns: ColumnType<Card>[] = [
      {
        title: t('Карта'),
        dataIndex: 'number',
      },
      {
        title: t('Баланс'),
        dataIndex: 'balance',
      },
      {
        title: t('Статус'),
        dataIndex: 'status',
        render: (status: Card['status']) => <Tag>{status}</Tag>,
      },
    ];
    const rows = toDataSource(record?.cards || []);
    return <Table columns={columns} pagination={false} dataSource={rows} />;
  };

  const columns: ColumnType<AccountResponseItem>[] = [
    {
      title: t('Банк'),
      dataIndex: 'bank',
      key: 'bank',
      render: (bank: Bank) => bank?.name,
    },
  ];
  const rows = toDataSource(accounts);
  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={rows}
      style={{ width: '100%' }}
      expandable={{
        expandedRowRender,
        expandedRowKeys: rows.map(row => row.key),
      }}
    />
  );
};

export default CustomerAccounts;
