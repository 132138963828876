import React, { useEffect, useState } from 'react';
import { Form, type FormInstance, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

interface FormData {
  card_number: string;
  amount: number | null;
}

type Props = {
  onChange?: (_data: FormData) => void;
  form?: FormInstance<any>;
};

const CardTransferForm: React.FC<Props> = ({ onChange = () => {}, form }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    card_number: '',
    amount: null,
  });

  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  useEffect(
    () => () => {
      const initialData = {
        card_number: '',
        amount: null,
      };
      if (form) {
        form.setFieldsValue(initialData);
      }
      setFormData(initialData);
    },
    [form],
  );
  const handleChange =
    (name: keyof FormData) =>
    (event: React.ChangeEvent<HTMLInputElement> | number | string) => {
      const value =
        typeof event === 'number' || typeof event === 'string'
          ? event
          : event.target.value;
      setFormData({ ...formData, [name]: value });
    };

  return (
    <>
      <Form.Item
        name="card_number"
        label={t('Номер карты')}
        style={{ marginBottom: 10 }}
        rules={[{ required: true }]}
      >
        <Input
          value={formData.card_number}
          onChange={e => handleChange('card_number')(e)}
        />
      </Form.Item>
      <Form.Item
        name="amount"
        label={t('Сумма')}
        rules={[{ required: true }]}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          value={formData.amount}
          onChange={value => handleChange('amount')(value || 0)}
        />
      </Form.Item>
    </>
  );
};

export default CardTransferForm;
