import React, { useState } from 'react';
import { Card, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { toDataSource } from '@root/lib';
import { useGetTestWebhooksQuery } from '@root/store/api/webhook';
import type { TestWebhook } from '@root/store/types';

const TestWebhookLayout: React.FC = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetTestWebhooksQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });

  const columns: ColumnType<TestWebhook>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('URL'),
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: t('Тело запроса'),
      dataIndex: 'event',
      key: 'body',
      render: value => <ReactJson src={value} />,
    },
    {
      title: t('Создано'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];
  console.log(data);

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<TestWebhook>(data?.data || []);
  return (
    <Card title={t('Тестовые коллбеки')}>
      <Table<TestWebhook>
        pagination={{
          ...pagination,
          total: data?.total || 1,
        }}
        loading={isLoading}
        columns={columns}
        dataSource={rows}
        onChange={handleTableChange}
      />
    </Card>
  );
};

export default TestWebhookLayout;
