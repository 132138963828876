import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Input } from 'antd';
import type { DescriptionsProps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { CustomerAccounts } from '@root/layout/CustomersLayout/CustomerLayout/parts';
import { useGetCustomerQuery } from '@root/store/api/customer';
import type { Address, Cert, CustomerDetails } from '@root/store/types';

const addressToText = (address: Address): string =>
  `Индекс: ${address?.postal_code}\nРегион: ${address?.state}\nГород: ${address?.city}\n${address?.street_address}`;

const docToText = (doc: Cert): string =>
  `${doc?.doc_number}\nВыдан ${doc?.issued_by}\n${dayjs(doc?.issue_date).format(
    'DD.MM.YYYY',
  )}\n${doc?.additional_info || ''}`;

const CustomerLayout: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [notFound, setNotFound] = useState(id === undefined);
  const { data, isLoading, isError, error } = useGetCustomerQuery(Number(id));
  useEffect(() => {
    // @ts-ignore
    if (isError && error.status === 404) {
      setNotFound(true);
    }
  }, [error, isError]);
  if (notFound) return <Navigate to={'/404'} />;
  const customer = data?.data || ({} as CustomerDetails);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('Место рождения'),
      children: customer.place_of_birth,
    },
    {
      key: '2',
      label: t('Дата рождения'),
      children: dayjs(customer.date_of_birth).format('DD.MM.YYYY'),
    },
    {
      key: '3',
      label: t('Телефон'),
      children: customer.phone_number,
    },
    {
      key: '4',
      label: t('Компания'),
      children: customer?.company?.name,
    },
    {
      key: '5',
      span: 2,
      label: t('Адрес'),
      children: (
        <Input.TextArea rows={5} value={addressToText(customer.address)} />
      ),
    },
    {
      key: '6',
      span: 2,
      label: t('Документ'),
      children: <Input.TextArea rows={5} value={docToText(customer.cert)} />,
    },
    {
      key: '7',
      span: 4,
      label: t('Аккаунты'),
      children: (
        // <CustomerAccounts data={customer?.devices[0]?.accounts || []} />
        <CustomerAccounts data={customer.devices} />
      ),
    },
  ];
  return (
    <Card
      title={`${customer.last_name} ${customer.first_name} ${customer.middle_name}`}
      loading={isLoading}
    >
      <Descriptions layout="vertical" items={items} column={4} />
    </Card>
  );
};

export default CustomerLayout;
