import React, { useEffect } from 'react';
import { Button, Modal, QRCode, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { appConfig } from '@root/config';
import { useGetApiTokenQuery } from '@root/store/api/api-token';
import { useLazyGetDeviceQuery } from '@root/store/api/device';
import type { Device } from '@root/store/types';
import styles from './QrCodeModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  deviceId: number;
};

const deviceToQrCode = (device: Device, apiKey: string) => {
  const { accounts, ...rest } = device;
  return {
    name: rest.name,
    info: rest.info,
    public_id: rest.public_id,
    secret: rest.secret,
    url: `${appConfig.apiUrl}/api`,
    api_key: apiKey,
    accounts: accounts?.map(acc => ({
      public_id: acc.public_id,
      bank: {
        name: acc.bank?.name,
      },
    })),
  };
};

const QrCodeModal: React.FC<Props> = ({ open, onClose, deviceId }) => {
  const { t } = useTranslation();
  const { data: tokenData, isLoading: isTokenLoading } = useGetApiTokenQuery();
  const [getDevice, { data: deviceData, isLoading }] = useLazyGetDeviceQuery();
  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId);
    }
  }, [deviceId, getDevice]);
  const qrCodeData = deviceToQrCode(
    deviceData?.data || ({} as Device),
    tokenData?.token || '',
  );
  const deviceStr = JSON.stringify(qrCodeData);
  return (
    <Modal
      open={open}
      onOk={onClose}
      title={t('QR code')}
      onCancel={onClose}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <div className={styles.contentWrapper}>
        <Skeleton loading={isLoading || isTokenLoading} active>
          <QRCode errorLevel="H" size={256} value={deviceStr} />
        </Skeleton>
      </div>
    </Modal>
  );
};

export default QrCodeModal;
