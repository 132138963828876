import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PaginationResponse,
  TestWebhookResponse,
} from '@root/store/types';

export const webhookApi = createApi({
  reducerPath: 'webhookApi',
  baseQuery,
  tagTypes: ['Webhook', 'TestWebhook'],
  endpoints: builder => ({
    getTestWebhooks: builder.query<
      PaginationResponse<TestWebhookResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/webhook/test?page=${page}&limit=${limit}`,
      providesTags: ['TestWebhook'],
    }),
  }),
});

export const { useGetTestWebhooksQuery } = webhookApi;
