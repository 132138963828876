export type Pagination = {
  page?: number;
  limit?: number;
};

export interface PaginationResponse<T extends any> {
  total: number;
  page: number;
  data: T;
}

export interface ApiResponse<T extends any> {
  status: string;
  data: T;
}

export interface Card {
  id: number;
  number: string;
  exp_month: number;
  exp_year: number;
  cvv: string;
  balance: number;
  is_locked: boolean;
  account_id: number;
  public_id: string;
  status: string;
}

export interface CardResponseItem extends Card {
  account: AccountResponseItem;
}

export type CardResponse = CardResponseItem[];

export type CardCreate = Omit<Card, 'id' | 'public_id'>;

export interface Bank {
  id: number;
  name: string;
  short_name: string | null;
  country: string;
  public_id: string;
}

export type BankCreate = Omit<Partial<Bank>, 'id' | 'public_id'>;

export type BankResponse = Bank[];

export interface Company {
  id: number;
  name: string;
  public_id: string;
  description?: string;
}

export type CompanyCreate = Omit<Company, 'id' | 'public_id'>;

export type CompanyResponse = Company[];

export interface Permission {
  permission_id: string;
  description?: string;
  read_only?: boolean;
}

export type PermissionResponse = Permission[];

export interface Role {
  role_id: string;
  read_only?: boolean;
}

export type RoleResponse = Array<Role & { permissions: Permission[] }>;

export interface User {
  id: number;
  name: string;
  email: string;
  company: Company;
  public_id: string;
  roles?: Role[];
  webhook_url?: string;
}

export interface UserCreate {
  name: string;
  email: string;
  password?: string;
  company_id?: number;
  role_ids: number[];
}

export type UserMeResponse = Partial<User> & { permissions: Permission[] };

export type UserResponse = Array<User & { role: Role[] }>;

export enum ProxyType {
  // eslint-disable-next-line
  http = 'http',
  // eslint-disable-next-line
  https = 'https',
  // eslint-disable-next-line
  socks4 = 'socks4',
  // eslint-disable-next-line
  socks5 = 'socks5',
}

export interface TwoFactorBridge {
  id: number;
  type: ProxyType;
  name: string;
  host: string;
  port: number;
  login: string;
  password: string;
  public_id?: string;
  secret_key?: string;
}

export type TwoFactorBridgeResponse = TwoFactorBridge[];

export interface Address {
  id: number;
  country_code: string;
  street_address: string;
  city: string;
  state?: string;
  postal_code: string;
}

export interface Cert {
  id: number;
  doc_number: string;
  issue_date: string;
  issued_by: string;
  expire_date: string;
  additional_info?: string;
}

export interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  middle_name?: string;
  date_of_birth: string;
  place_of_birth: string;
  phone_number: string;
  public_id: string;
  cert_id: number;
  address_id: number;
  company_id: number;
}

export interface CustomerResponseItem extends Customer {
  cert: Cert;
  address: Address;
}

export type CustomerResponse = CustomerResponseItem[];

export interface CustomerDetails extends Customer {
  cert: Cert;
  address: Address;
  devices: Device[];
  company: Company;
}

export interface CustomerCreate
  extends Omit<Customer, 'cert_id' | 'address_id' | 'public_id'> {
  cert: Omit<Cert, 'id'>;
  address: Omit<Address, 'id'>;
}

export interface Device {
  id: number;
  name: string;
  info?: string;
  enabled: boolean;
  bridge_connected: boolean;
  permissions_granted: boolean;
  secret: string;
  phone_number: string;
  customer_id: number;
  public_id: string;
  customer?: Customer;
  accounts?: Account[];
}

export type DevicesResponse = Device[];

export interface DeviceCreate {
  name: string;
  info?: string;
  secret: string;
  customer_id: number;
  phone_number: string;
}

export interface Account {
  id: number;
  login?: string;
  password: string;
  type_of_creds: 'login' | 'phone';
  phone: string;
  other_phones?: string;
  code_word?: string;
  bank_id: number;
  customer_id: number;
  public_id: string;
  device_id: number;
  two_factor_bridge_id: number;
  twoFactorBridge?: TwoFactorBridge;
  customer?: Customer;
  bank?: Bank;
  device?: Device;
  cards?: Card[];
  _count?: {
    transactions: number;
  };
}

export type AccountCreate = Omit<Account, 'id' | 'public_id'>;

export type AccountResponseItem = Account;

export type AccountResponse = AccountResponseItem[];

export interface Transaction {
  id: number;
  account_id: number;
  time_received: string;
  transaction_code: string;
  amount: number;
  debit: boolean;
  message: string;
  comment: string;
  conter_agent_id: number;
}

export type TransactionResponse = Transaction[];

export interface Notification {
  id: number;
  type: string;
  body: string;
  source: string;
  device_id: number;
  ts: string;
  public_id: string;
}

export type NotificationResponse = Notification[];

export interface BotSettings {
  id: number;
  account_id: number;
  stream_key: string;
  enabled: boolean;
  browser: string;
  public_id: string;
  account?: Account;
}

export type BotSettingsCreate = Omit<BotSettings, 'id' | 'public_id'>;

export type BotSettingsResponseItem = BotSettings;

export type BotSettingsResponse = BotSettingsResponseItem[];

export interface PostResponse {
  status: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  status: string;
}

export interface ApiToken {
  token: string;
}

export type ApiTokenResponse = ApiToken;

export interface PaymentInNotification {
  id: number;
  account_id: number;
  dttm: string;
  amount: number;
  message: string;
  account?: Account & { bank?: Bank };
}

export type PaymentInNotificationResponse = PaymentInNotification[];

export interface TestWebhook {
  id: number;
  url: string;
  event: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export type TestWebhookResponse = TestWebhook[];
