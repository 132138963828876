import React from 'react';
import {
  BankOutlined,
  CloudServerOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  KeyOutlined,
  MehOutlined,
  MessageOutlined,
  MoneyCollectOutlined,
  SmileOutlined,
  TabletOutlined,
  TeamOutlined,
  TransactionOutlined,
  UserOutlined,
  RobotOutlined,
  ScheduleOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import {
  Account,
  Bank,
  BotSettings,
  Card,
  Company,
  Customer,
  Customers,
  Dashboard,
  Device,
  FinTasks,
  NotFound,
  Notifications,
  Permission,
  Profile,
  Proxy,
  Role,
  Transactions,
  User,
  Webhook,
} from '@root/pages';

type NavRoute = {
  name: string;
  link: string;
  icon?: React.ForwardRefExoticComponent<any>;
  label?: string;
  permissions?: string[];
  nested?: NavRoute[];
  element?: React.ReactNode;
};

const i18next = {
  t: (s: string) => s,
};

export const navRoutes: NavRoute[] = [
  {
    name: i18next.t('Dashboard'),
    link: '/dashboard',
    icon: DashboardOutlined,
    label: i18next.t('Dashboard'),
    permissions: [],
    element: <Dashboard />,
  },
  {
    name: i18next.t('Банки'),
    link: '/finance/bank',
    icon: BankOutlined,
    label: i18next.t('Банки'),
    permissions: ['can_view_banks', 'can_view_all'],
    element: <Bank />,
  },
  {
    name: i18next.t('Аккаунты'),
    link: '/finance/account',
    icon: MoneyCollectOutlined,
    label: i18next.t('Аккаунты'),
    permissions: ['can_view_accounts', 'can_view_all'],
    element: <Account />,
  },
  {
    name: i18next.t('Карты'),
    link: '/finance/card',
    icon: CreditCardOutlined,
    label: i18next.t('Карты'),
    permissions: ['can_view_cards', 'can_view_all'],
    element: <Card />,
  },
  {
    name: i18next.t('Компании'),
    link: '/company',
    icon: TeamOutlined,
    label: i18next.t('Компании'),
    permissions: ['can_view_companies', 'can_view_all'],
    element: <Company />,
  },
  {
    name: i18next.t('Разрешения'),
    link: '/admin/permission',
    icon: KeyOutlined,
    label: i18next.t('Разрешения'),
    permissions: ['can_view_permissions', 'can_view_all'],
    element: <Permission />,
  },
  {
    name: i18next.t('Роли'),
    link: '/admin/role',
    icon: MehOutlined,
    label: i18next.t('Роли'),
    permissions: ['can_view_roles', 'can_view_all'],
    element: <Role />,
  },
  {
    name: i18next.t('Пользователи'),
    link: '/admin/user',
    icon: UserOutlined,
    label: i18next.t('Пользователи'),
    permissions: ['can_view_users', 'can_view_all'],
    element: <User />,
  },
  {
    name: i18next.t('Устройства'),
    link: '/device',
    icon: TabletOutlined,
    label: i18next.t('Устройства'),
    permissions: ['can_view_devices', 'can_view_all'],
    element: <Device />,
  },
  {
    name: i18next.t('Прокси'),
    link: '/proxy',
    icon: CloudServerOutlined,
    label: i18next.t('Прокси'),
    permissions: ['can_view_proxies', 'can_view_all'],
    element: <Proxy />,
  },
  {
    name: i18next.t('Абоненты'),
    link: '/customers',
    icon: SmileOutlined,
    label: i18next.t('Абоненты'),
    permissions: ['can_view_customers', 'can_view_all'],
    element: <Customers />,
  },
  {
    name: i18next.t('Транзакции'),
    link: '/transactions',
    icon: TransactionOutlined,
    label: i18next.t('Транзакции'),
    permissions: ['can_view_transactions', 'can_view_all'],
    element: <Transactions />,
  },
  {
    name: i18next.t('Уведомления'),
    link: '/notifications',
    icon: MessageOutlined,
    label: i18next.t('Уведомления'),
    permissions: ['can_view_notifications', 'can_view_all'],
    element: <Notifications />,
  },
  {
    name: i18next.t('Боты'),
    link: '/bot-settings',
    icon: RobotOutlined,
    label: i18next.t('Боты'),
    permissions: ['can_view_bot_settings', 'can_view_all'],
    element: <BotSettings />,
  },
  {
    name: i18next.t('Фин. задания'),
    link: '/fin-tasks',
    icon: ScheduleOutlined,
    label: i18next.t('Фин. задания'),
    permissions: ['can_view_fin_tasks', 'can_view_all'],
    element: <FinTasks />,
  },
  {
    name: i18next.t('Коллбеки (тест)'),
    link: '/webhook-test',
    icon: GlobalOutlined,
    label: i18next.t('Коллбеки (тест)'),
    permissions: ['can_view_web_hooks', 'can_view_all'],
    element: <Webhook />,
  },
];

type Route = {
  link: string;
  element?: React.ReactNode;
  permissions?: string[];
  nested?: Route[];
};

const subRoutes: Route[] = [
  {
    link: '/profile',
    element: <Profile />,
    permissions: ['can_view_profile', 'can_view_all'],
  },
  {
    link: '/customers/:id',
    permissions: ['can_view_customers', 'can_view_all'],
    element: <Customer />,
  },
  {
    link: '/404',
    permissions: [],
    element: <NotFound />,
  },
];

const routes = [...navRoutes, ...subRoutes];

export default routes;
