import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Form,
  Input,
  notification,
  Select,
  Table,
  type TablePaginationConfig,
} from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  type CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { AccountForm, type FormHandlers } from '@root/feature/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { toDataSource } from '@root/lib';
import {
  useDeleteAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from '@root/store/api/account';
import { useGetProxyQuery } from '@root/store/api/proxy';
import type {
  Account,
  AccountResponseItem,
  Customer,
  Device,
  TwoFactorBridge,
} from '@root/store/types';

const AccountLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const {
    data: accountsData,
    isLoading: accountsLoading,
    isError: isAccountsError,
  } = useGetAccountQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const {
    data: proxyData,
    isLoading: isProxyLoading,
    isError: isProxyError,
    refetch: refetchProxy,
  } = useGetProxyQuery({
    page: 1,
    limit: 50,
    unlinked: true,
  });

  const [deleteDevice, { isError: isDeleteError }] = useDeleteAccountMutation();
  const [update, { isLoading: isUpdating }] = useUpdateAccountMutation();

  const isLoading = accountsLoading || isProxyLoading;
  const isError = isAccountsError || isProxyError || isDeleteError;

  const rows = toDataSource<AccountResponseItem>(accountsData?.data || []);

  useEffect(() => {
    refetchProxy();
  }, [editableRowId]);

  const proxyList: TwoFactorBridge[] = useMemo(() => {
    let currentProxy;
    if (editableRowId) {
      currentProxy = rows.find(
        row => row.id === editableRowId,
      )?.twoFactorBridge;
    }
    const data = (proxyData?.data || []) as TwoFactorBridge[];
    return !!currentProxy ? [currentProxy, ...data] : data;
  }, [editableRowId, proxyData?.data, rows]);

  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Device) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };

  useErrorNotification(isError, t('Что-то пошло не так'));

  const handleDeleteRow = (row: Device) => () => {
    deleteDevice(row.id);
  };

  const isEditing = (id: number) => id === editableRowId;

  const credsType: Record<string, string> = {
    login: t('Логин'),
    phone: t('Телефон'),
  };
  const columns: ColumnType<AccountResponseItem>[] = [
    {
      title: t('Логин'),
      dataIndex: 'login',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'login',
        title: t('Login'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Телефон'),
      dataIndex: 'phone',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'phone',
        title: t('Телефон'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Тип входа'),
      dataIndex: 'type_of_creds',
      width: '15%',
      render: (value: string) => credsType[value],
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'type_of_creds',
        title: t('Тип входа'),
        inputNode: (
          <Select placeholder={t('Укажите тип входа')}>
            <Select.Option value="login">{t('Логин')}</Select.Option>
            <Select.Option value="phone">{t('Телефон')}</Select.Option>
          </Select>
        ),
      }),
    },
    {
      title: t('Абонент'),
      dataIndex: 'device',
      width: '15%',
      render: (device: Device) =>
        `${device?.customer?.first_name} ${device?.customer?.last_name}`,
    },
    // {
    //   title: t('Банк'),
    //   dataIndex: 'bank',
    //   width: '10%',
    //   render: (value: Bank) => value.name,
    // },
    {
      title: t('Прокси'),
      dataIndex: 'twoFactorBridge',
      width: '20%',
      render: value => {
        if (!value) {
          return '';
        }
        return `${value.host}:${value.port}`;
      },
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'two_factor_bridge_id',
        title: t('Прокси'),
        itemType: 'select',
        inputNode: (
          <Select placeholder={t('Выберите прокси')} loading={isProxyLoading}>
            {proxyList.map((proxy, key) => (
              <Select.Option key={key} value={proxy.id}>
                {`${proxy.name} : ${proxy.host}`}
              </Select.Option>
            ))}
          </Select>
        ),
      }),
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '15%',
      ellipsis: true,
    },
    {
      title: t(''),
      dataIndex: '_count',
      width: '5%',
      render: count => count.transactions,
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Account;
      await update({ ...row, id: editableRowId as number });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  return (
    <>
      {contextHolder}
      <Crud
        title={t('Аккаунты')}
        drawerTitle={t('Добавить аккаунт')}
        ref={crudRef}
        drawerContent={<AccountForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<AccountResponseItem>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: accountsData?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default AccountLayout;
